import React from 'react';

import { Helmet } from 'react-helmet'

export default () => {
    const { charset, pageTitle } = window.appData;

    return (
        <Helmet>
            <meta charSet={charset} />
            <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <title>{pageTitle}</title>
        </Helmet>
    )
}
