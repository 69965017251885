import React, { useState } from "react";
import { useAuth } from "../../context/AuthProvider";
import { isLoggedIn } from "../../utils/client";

export default ({
  title = true,
  onEmailChange,
  email: propEmail = "",
  onLoginSuccess,
}) => {
  const [username, setUsername] = useState(propEmail);
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [loggedIn, setLoggedIn] = useState(isLoggedIn());
  const [error, setError] = useState(false);

  const { login } = useAuth();

  async function handleOnSubmit(e) {
    e.preventDefault();

    setLoading(true);

    const data = {
      LoginForm: {
        username: username,
        password: password,
      },
    };
    const loginResult = await login(data);
    if (loginResult) {
      setError(false);
      setLoggedIn(loginResult);
      onLoginSuccess();
    } else {
      setError(true);
    }
    setLoading(false);
  }

  const handleEmailChange = (e) => {
    setUsername(e.target.value);
    onEmailChange(e.target.value);
  };

  return (
    <form onSubmit={handleOnSubmit}>
      {!loggedIn && (
        <>
          {title && (
            <>
              <h3>User Login</h3>
              <hr />
            </>
          )}

          <div className="form-floating mb-3">
            <input
              value={username}
              onChange={(e) => handleEmailChange(e)}
              className="form-control"
              id="loginform-username"
              placeholder="name@example.com"
              type="email"
            />
            <label htmlFor="loginform-username">Email</label>
          </div>
          <div className="form-floating mb-3">
            <input
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="form-control"
              id="loginform-password"
              type="password"
              placeholder="Password"
            />
            <label htmlFor="loginform-password">Password</label>
          </div>

          {error && (
            <div className="alert alert-danger" role="alert">
              Username or password was incorrect, try again or reset your
              password.
            </div>
          )}

          <button
            disabled={loading ? true : false}
            type="submit"
            className="btn btn-primary w-100 mb-2"
          >
            {loading ? "Signing In..." : "Sign In"}
          </button>
        </>
      )}
      {loggedIn && <div className="text-center">You are signed in.</div>}
    </form>
  );
};
