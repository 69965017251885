import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import loadable from "@loadable/component";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// Context
import AppProvider from "./context/AppProvider";

// Custom Components
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import Head from "./components/Head/Head";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";
import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary";
import LoadingSpinner from "./components/LoadingSpinner/LoadingSpinner";

// Pages
import Dashboard from "./pages/Dashboard";
import NotFound from "./pages/NotFound";

// Lazy loaded Pages
const loadableConfig = {
  fallback: <LoadingSpinner />,
};

const LoadableInstances = React.lazy(
  () => import("./pages/Instances")
  // loadableConfig
);
const LoadableMemberSearch = React.lazy(
  () => import("./pages/MemberSearch")
  // loadableConfig
);

const LoadableSingleInstance = React.lazy(
  () => import("./pages/SingleInstance")
  // loadableConfig
);

const LoadableAddNewInstance = React.lazy(
  () => import("./pages/AddNewInstance")
  // loadableConfig
);

const LoadableSendUserMessage = React.lazy(
  () => import("./pages/SendUserMessage")
  // loadableConfig
);

// Global styling
import "../sass/style.sass";

function App() {
  // If the current URL has `/api/` in the string then return an emtpy div
  // and let the back-end handle the call
  if (window.location.href.includes("/api/")) {
    return <div />;
  }

  return (
    <Router>
      <ErrorBoundary>
        <Head />
        <Navbar />
        <div className="container-fluid main-content">
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route
              path="/instances"
              element={
                <PrivateRoute>
                  <Suspense fallback={<LoadingSpinner />}>
                    <LoadableInstances />
                  </Suspense>
                </PrivateRoute>
              }
            />
            <Route
              path="/instance/:instanceId"
              element={
                <PrivateRoute>
                  <Suspense fallback={<LoadingSpinner />}>
                    <LoadableSingleInstance />
                  </Suspense>
                </PrivateRoute>
              }
            />
            <Route
              path="/user-search"
              element={
                <PrivateRoute>
                  <Suspense fallback={<LoadingSpinner />}>
                    <LoadableMemberSearch />
                  </Suspense>
                </PrivateRoute>
              }
            />
            <Route
              path="/add-new-instance"
              element={
                <PrivateRoute>
                  <Suspense fallback={<LoadingSpinner />}>
                    <LoadableAddNewInstance />
                  </Suspense>
                </PrivateRoute>
              }
            />
            <Route
              path="/send-user-notification"
              element={
                <PrivateRoute>
                  <Suspense fallback={<LoadingSpinner />}>
                    <LoadableSendUserMessage />
                  </Suspense>
                </PrivateRoute>
              }
            />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
        <div className="footer-wrapper">
          <Footer />
        </div>
      </ErrorBoundary>
    </Router>
  );
}

const appRoot = document.getElementById("app-root");

if (appRoot) {
  ReactDOM.render(
    <AppProvider>
      <App />
    </AppProvider>,
    appRoot
  );
}
