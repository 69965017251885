import React from 'react';
import { AuthProvider } from './AuthProvider';
import { UserProvider } from './UserProvider';

export default function AppProvider({children}) {
    return (
        <AuthProvider>
            <UserProvider>{children}</UserProvider>
        </AuthProvider>
    )
}
