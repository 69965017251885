import React, { Component } from 'react';

export default class ErrorBoundary extends Component {
    constructor(props) {
        super(props)

        this.state = {
            hasError: false
        };
    }

    static getDerivedStateFromError(_) {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        console.error(error, errorInfo);
    }


    render() {
        if(this.state.hasError) {
            return (
                <>
                    <h1>There was an error.</h1>
                    <p>Try <a href="#" onClick={() => window.location.reload()}>reloading</a> the page, if the problem persists please contact support.</p>
                </>
            )
        } else {
            return this.props.children;
        }
    }
}