import React from 'react';

import { Chart } from 'react-charts';

export default () => {
    const data = React.useMemo(
        () => [
            {
                label: 'Series 1',
                data: [[0, 1], [1, 0], [2, 4], [3, 2], [4, 7]]
            },
            {
                label: 'Series 2',
                data: [[0, 3], [1, 1], [2, 5], [3, 6], [4, 4]]
            }
        ],
        []
    )

    const series = React.useMemo(
        () => ({
            type: 'bar'
        }),
        []
    )
    
    const axes = React.useMemo(
        () => [
            { primary: true, type: 'ordinal', position: 'bottom' },
            { type: 'linear', position: 'left', stacked: false }
        ],
        []
    )

    return (
        <div style={{ width: '500px', height: '300px' }}>
            <Chart
                data={data}
                axes={axes}
                series={series}
                tooltip
                dark />
        </div>
    )
}