// localStorage keys
export const TOKEN_KEY = "__cmcp_token__";
export const USER_DATA_KEY = "__cmcp_user_data__";

// API Endpoints
const API_PREFIX = "/api/";

export const endpoints = {
  LOGIN_ENDPOINT: `${API_PREFIX}site/login`,
  CW_INDEX_ENDPOINT: `${API_PREFIX}causeway-instance/list-all`,
  CW_INSTANCE: `${API_PREFIX}causeway-instance/index/`,
  CW_INSTANCE_STATUS: `${API_PREFIX}causeway-instance/toggle-status`,
  MEMBER_SEARCH_ENDPOINT: `${API_PREFIX}causeway-instance/user-search?email=`,
  ADD_NEW_INSTANCE: `${API_PREFIX}causeway-instance/site-install-request`,
  SEND_USER_MESSAGE: `${API_PREFIX}causeway-instance/send-message`,
};

// URLS
export const BB_REPO_URL =
  "https://bitbucket.org/vtmwebservices/causeway-core/branch/";

export const NAVBAR_LINKS = [
  {
    label: "Instances",
    url: "/instances",
  },
  {
    label: "Add New Instance",
    url: "/add-new-instance",
  },
  {
    label: "User Search",
    url: "/user-search",
  },
  {
    label: "Send User Notification",
    url: "/send-user-notification",
  },
];
