import React from 'react';

import  './loadingSpinner.sass'

export default ({color}) => (
    <div className="mobile-container">
        <div className="lds-ripple">
            <div style={{ borderColor: color }} />
            <div style={{ borderColor: color }} />
        </div>
    </div>
)