import { TOKEN_KEY, USER_DATA_KEY } from "../constants";
import axios from "axios";

// Fetch method, applies correct config based
async function client(endpoint, { body, ...customConfig }) {
  const headers = {
    "content-type": "application/json",
    "Access-Control-Allow-Headers": "*",
    Accept: "application/json",
  };
  const token = getToken();

  if (token) {
    headers["Authorization"] = `Bearer ${token}`;
  }

  const csrfToken = document.head.querySelector(
    'meta[name="csrf-token"]'
  ).content;

  if (csrfToken && body) {
    headers["X-CSRF-TOKEN"] = csrfToken;
  }

  const fetchConfig = {
    url: endpoint,
    method: body ? "POST" : "GET",
    ...customConfig,
    headers: {
      ...headers,
      ...customConfig.headers,
    },
  };

  if (body) {
    // fetchConfig.data = JSON.stringify(body)
    fetchConfig.data = body;
  }

  try {
    const response = await axios(fetchConfig);
    return response;
  } catch (error) {
    if (error.response.status === 401) {
      console.error("Unauthorized route");
      clearUserData();
      window.location.assign("/");
    } else {
      //   throw new Error(`Unable to complete fetch request to ${endpoint}`);
      return error.response;
    }
  }
}

function getToken() {
  return window.localStorage.getItem(TOKEN_KEY);
}

function setToken(token) {
  window.localStorage.setItem(TOKEN_KEY, token);
}

function clearUserData() {
  window.localStorage.removeItem(TOKEN_KEY);
  window.localStorage.removeItem(USER_DATA_KEY);
}

function isLoggedIn() {
  return Boolean(getToken());
}

function getUserData() {
  return window.localStorage.getItem(USER_DATA_KEY);
}

export { client, getToken, setToken, isLoggedIn, getUserData, clearUserData };
