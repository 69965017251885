import React from 'react'
import { useAuth } from './AuthProvider'
import { isLoggedIn } from '../utils/client'

const UserContext = React.createContext()

const UserProvider = props => (

    // this should pass the user data, for now it only passes the isLoggedIn value
    <UserContext.Provider value={isLoggedIn()} {...props} />
)

const useUser = () => React.useContext(UserContext)

export { UserProvider, useUser }