import React from "react";

import { Link } from "react-router-dom";

export default ({ link, active }) => {
  // let active = useMatch({ path: link.url, exact: true });

  return (
    <Link className={`nav-link ${active ? "text-primary" : ""}`} to={link.url}>
      {link.label}
    </Link>
  );
};
