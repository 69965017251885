import React from "react";

import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
// import { Link } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";

import LogoutButton from "../LogoutButton/LogoutButton";

const UserMenu = () => {
  return (
    <Nav className="me-2">
      <NavDropdown id="userMenuDropdown" title="Username" menuVariant="dark">
        <LinkContainer to="/settings">
          <NavDropdown.Item>Settings</NavDropdown.Item>
        </LinkContainer>
        <NavDropdown.Divider />
        <LogoutButton />
      </NavDropdown>
    </Nav>
  );
};
export default UserMenu;
