import React from "react";
import NavbarLink from "./NavbarLink";
// import LogoutButton from "../LogoutButton/LogoutButton";
import BrandIcon from "../BrandIcon/BrandIcon";

import { useMatch, Link } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import { isLoggedIn } from "../../utils/client";

import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import UserMenu from "../UserMenu/UserMenu";
import { NAVBAR_LINKS } from "../../constants";

export default () => {
  const isDashboard = useMatch({
    path: "/",
    exact: true,
  });

  const loggedIn = isLoggedIn();

  return (
    <Navbar bg="dark" variant="dark" expand="sm">
      <LinkContainer to="/" className="ms-2">
        <Navbar.Brand>
          <BrandIcon bgColor={isDashboard ? "#F67B00" : "#fff"} size="40" />
        </Navbar.Brand>
      </LinkContainer>
      {loggedIn && (
        <Navbar.Collapse id="navbar-content">
          <Nav className="me-auto">
            {NAVBAR_LINKS.map((link) => {
              const active =
                useMatch({ path: link.url, exact: true }) != null
                  ? true
                  : false;
              // return <NavbarLink active={active} link={link} key={link.url} />;
              return (
                <LinkContainer key={link.label} to={link.url}>
                  <Nav.Link active={active}>{link.label}</Nav.Link>
                </LinkContainer>
              );
            })}
          </Nav>
          {/* <UserMenu /> */}
        </Navbar.Collapse>
      )}
    </Navbar>
  );
};
