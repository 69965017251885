import React from "react";
import {
  client,
  getToken,
  getUserData,
  setToken,
  clearUserData,
} from "../utils/client";
import { endpoints, USER_DATA_KEY, TOKEN_KEY } from "../constants";
const { LOGIN_ENDPOINT } = endpoints;

const AuthContext = React.createContext();

function AuthProvider(props) {
  // Check user auth token in browser storage
  // const userToken = getToken();

  // Display loading spinner while fetching user data
  const userData = getUserData();

  // Add user handler functions

  // Make a login request
  const login = async (data) => {
    console.log("logging user in...");

    const response = await client(LOGIN_ENDPOINT, { body: data });
    if (response.data.token) {
      // After getting the user data and token from the back-end, we save it in localStorage
      setToken(response.data.token);
      // reload page
      window.location.assign(window.location);
      return true;
    } else {
      return false;
    }
    // if (response.status === 200) {
    //   // SUCCESSFUL REQUEST
    // } else {
    //   return false;
    // }
  };

  // Sign a new user up
  const register = () => {
    console.log("registering new user");
  };

  // Clear token in localStorage and also wipe all user data
  const logout = () => {
    console.log("logging user out");
    clearUserData();

    // Reload the page so the user gets redirected
    window.location.assign(window.location);
  };

  return (
    <AuthContext.Provider
      value={{ userData, login, logout, register }}
      {...props}
    />
  );
}

const useAuth = () => React.useContext(AuthContext);

export { AuthProvider, useAuth };
