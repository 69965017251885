import React, { useState } from "react";

export default ({ email: propEmail = "" }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [email, setEmail] = useState(propEmail);
  const [password, setPassword] = useState("");

  const handleOnSubmit = (e) => {
    console.log("submitting form");
    // TODO: hit endpoint with formdata to create account
    // endpoint will either return a token for success or some failure code
    e.preventDefault();
  };

  return (
    <form onSubmit={handleOnSubmit}>
      <div className="form-floating mb-3">
        <input
          id="floatingEmail"
          placeholder="name@example.com"
          className="form-control"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <label htmlFor="floatingEmail">Email</label>
      </div>
      <div className="form-floating mb-3">
        <input
          id="floatingPassword"
          placeholder="*********"
          className="form-control"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <label htmlFor="floatingEmail">Password</label>
      </div>
      <div className="col-12">
        <button className="btn btn-primary mb-2 w-100" type="submit">
          Create Account
        </button>
      </div>
    </form>
  );
};
