import React from "react";

export default () => (
  <footer className="bg-dark text-white pt-3 pb-3">
    <div className="container-fluid">
      <span className="text-muted text-small">
        &copy; Omnia Digital Solutions {new Date().getFullYear()}
      </span>
    </div>
  </footer>
);
