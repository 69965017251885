import React from "react";

import { Navigate } from "react-router-dom";

import { isLoggedIn } from "../../utils/client";

export default ({ children }) => {
  console.log(isLoggedIn());
  return isLoggedIn() ? children : <Navigate to="/" />;
};
