import React, { useState } from "react";

import { Helmet } from "react-helmet";
import Graph from "../components/Graph/Graph";
import { isLoggedIn } from "../utils/client";

import LoginForm from "../components/LoginForm/LoginForm";
import BrandIcon from "../components/BrandIcon/BrandIcon";
import NewUserForm from "../components/NewUserForm/NewUserForm";

import { NAVBAR_LINKS } from "../constants";
import { ListGroup } from "react-bootstrap";
import { Link } from "react-router-dom";

export default ({ onSubmit, onChange, formData }) => {
  const [newUser, setNewUser] = useState(false);
  const [email, setEmail] = useState("");
  const [loggedIn, setLoggedIn] = useState(isLoggedIn());
  //   const loggedIn = isLoggedIn();
  return (
    <>
      {loggedIn && (
        <>
          <Helmet>
            <title>Dashboard</title>
          </Helmet>
          <div className="d-flex flex-column align-items-center justify-content-center h-100 pt-5">
            <h1>Welcome to the CMCP Dashboard</h1>
            <ListGroup variant="flush">
              {NAVBAR_LINKS.map((link) => (
                <ListGroup.Item key={link.url} className="bg-transparent">
                  <Link to={link.url}>{link.label}</Link>
                </ListGroup.Item>
              ))}
            </ListGroup>
          </div>
        </>
      )}
      {!loggedIn && (
        <>
          <Helmet>
            <title>Sign In</title>
          </Helmet>
          <div className="container-mobile text-center">
            <div className="mb-3">
              <BrandIcon size="250" />
            </div>
            {!newUser && (
              <>
                <LoginForm
                  title={false}
                  email={email}
                  onEmailChange={(e) => setEmail(e)}
                  onLoginSuccess={() => setLoggedIn(true)}
                />
                <a
                  className="mt-2"
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    setNewUser(true);
                  }}
                >
                  Create A New Account &rarr;
                </a>
              </>
            )}
            {newUser && (
              <>
                <NewUserForm email={email} />
                <p>
                  Already have an account?&nbsp;
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      setNewUser(false);
                    }}
                  >
                    sign in here.
                  </a>
                </p>
              </>
            )}
          </div>
        </>
      )}
    </>
  );
};
