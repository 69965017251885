import React from "react";

import { isLoggedIn } from "../../utils/client";
import { useAuth } from "../../context/AuthProvider";

import NavDropdown from "react-bootstrap/NavDropdown";

import LockFill from "../Icons/LockFill";

export default () => {
  const { logout } = useAuth();

  const handleClick = (e) => {
    e.preventDefault();
    logout();
  };

  return (
    <NavDropdown.Item href="#" onClick={handleClick} className="dropdown-item">
      Sign Out &rarr;
    </NavDropdown.Item>
  );
};
